import React, { Component } from 'react'
import cookies from 'next-cookies'

import { logError } from '../utils'
import { ErrorPage } from '../frontend'
import getSharedLayout from './shared-layout'

class Error extends Component {
  static async getInitialProps(ctx) {
    const { res, err } = ctx
    const statusCode = res ? res.statusCode : err ? err.statusCode : null

    const allCookies = cookies(ctx)

    let props = { statusCode, allCookies }

    if (err) {
      const stackTrace = err.stack ? err.stack.toString() : null
      props['error'] = {
        message: err.message,
        stack: stackTrace,
      }
      logError(err, 'nextjs:_error')
    }

    return props
  }

  render() {
    return <ErrorPage {...this.props} />
  }
}

Error.getLayout = getSharedLayout

export default Error
