import React, { Component } from 'react'
import cookies from 'next-cookies'
import qs from 'qs'

import { LandingPage, ListingPage, DetailPage } from '../../frontend'
import { fetchPageData, logError, redirect, wait } from '../../utils'

import ErrorPage from '../_error'
import getSharedLayout from '../shared-layout'

const pageComponents = {
  page: LandingPage,
  category: ListingPage,
  manufacturer: ListingPage,
  'makaira-productgroup': DetailPage,
}

export default class Entry extends Component {
  static async getInitialProps(ctx) {
    const { query, res } = ctx

    const { seoUrl, ...params } = qs.parse(query)

    const allCookies = cookies(ctx)

    // Fix the URL and redirect when the path starts with leading double-slashes to avoid breaking the browser history
    const regex = /^\/\//
    if (seoUrl?.match(regex)) {
      const target = seoUrl.replace(regex, '/')
      redirect({ ctx, target })
    }

    try {
      const pageData = await fetchPageData({ ctx })

      // Setting custom header for makaira pages
      if (pageData && res) {
        res.set('X-makaira-action', pageData.type)
      }

      if (pageData.type == 'redirect') {
        const {
          data: { target, code },
        } = pageData

        redirect({ ctx, target, code })
      }

      return { pageData, params, allCookies }
    } catch (error) {
      /**
       * Catching an error inside getInitialProps means that - in most cases - the
       * current URL was not found in any ElasticSearch document.
       *
       * In this case, we want to make use of a failover mechanism that sets
       * a 404 status-code in the response and thereby triggers the request to be
       * re-routed to the "real" Shop-System by the load balancer.
       *
       * On the server this is achived by setting the status code and rendering the
       * <ErrorPage /> component.
       *
       * On the client this is achived by refreshing the current page, thereby triggering
       * a server-side rendering which in turn re-routes at the load balancer again.
       *
       * Note: For the client side we have to make use of a small hack. The reason is, that
       * if you just set `window.location.href`, Next.js will not wait for the page transition
       * and instead continue to run through its lifecycle methods, causing the <ErrorPage />
       * to show up before actually reloading the page. To work around this issue, we set a
       * timeout after changing `window.location.href`.
       */
      // 404's are meant to be re-routed to Magento as fallback, so there's no point in logging it
      if (error.code !== 404) {
        logError(error, 'nextjs:entry')
      }
      if (res) {
        res.statusCode = error.code || 404
      } else {
        const normalizedSeoUrl = seoUrl.replace(/^\//, '') // replace leading slash from path
        window.location.href = `/${normalizedSeoUrl}`

        // Do not remove for now! See comment above.
        await wait(30000)
      }
      /**
       * Returning an empty here is intentional, see:
       * https://github.com/zeit/next.js/blob/master/errors/empty-object-getInitialProps.md
       */
      return {}
    }
  }

  render() {
    const { pageData } = this.props
    const { type } = pageData || {}
    const PageComponent = pageComponents[type]

    return PageComponent ? <PageComponent /> : <ErrorPage statusCode={404} />
  }
}

Entry.getLayout = getSharedLayout
